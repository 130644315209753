<template>
  <div style="width: 70%">
    <!--begin::Content header-->
    <div
      v-if="0"
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <!-- <span class="font-weight-bold font-size-3 text-dark-60">
        Chưa có tài khoản?
      </span> -->
      <!-- <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'register' }"
      >
        Đăng ký!
      </router-link> -->
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->

    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-5">
        <h3 class="font-size-h2" style="font-weight: 900">
          Chào mừng đến với D-Meet 👋
        </h3>
        <p class="text-muted font-weight-semi-bold">
          Vui lòng nhập tên đăng nhập và mật khẩu theo tài khoản D-Core
        </p>
      </div>

      <div class="login-form-container mt-20 p-10">
        <p class="font-size-h2 text-center" style="font-weight: 900">
          Đăng nhập
        </p>
        <!--begin::Form-->
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="input-1-live-feedback"
              placeholder="Tên đăng nhập"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Tên đăng nhập không được để trống.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              placeholder="Mật khẩu"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Mật khẩu không được để trống.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div
            class="form-group d-flex flex-wrap justify-content-between align-items-center"
          >
            <a href=""></a>
            <button
              ref="kt_login_signin_submit"
              class="submit-button w-100 btn font-weight-bold px-9 py-4 my-3 font-size-3"
            >
              Đăng nhập
            </button>
          </div>
          <!--end::Action-->
          <p class="mt-5 text-center" style="font-weight: 900">
            Vui lòng liên hệ IT nếu chưa có tài khoản D-Core
          </p>
        </b-form>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Signin-->
    <select-department-modal
      :userInfo="userInfo"
      :accessByDCore="accessByDCore"
    />
    <loading-screen />
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import { saveToken } from "@/core/services/jwt.service";
import AuthModule from "@/core/services/store/auth.module.js";
import { SET_AUTH } from "@/core/services/store/auth.module.js";
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
import { removeAscent } from "@/core/helpers/stringHandling";
import SelectDepartmentModal from "@/view/content/modals/SelectDepartmentModal.vue";
import { mapGetters } from "vuex";
import LoadingScreen from "@/view/content/screen/LoadingScreen.vue";
// import { LOADING_RESET } from "@/core/services/store/store";
import { LOADING_TRUE, LOADING_RESET } from "@/core/services/store/store";

export default {
  mixins: [validationMixin],
  name: "login",
  components: {
    SelectDepartmentModal,
    LoadingScreen,
  },

  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      userInfo: null,
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store.dispatch(LOADING_TRUE, "black transparent 50%");
      this.$store
        .dispatch(LOGIN, { username, password })
        // go to which page after successfully login
        // .then(() => this.$router.push({ name: "home" }));
        .then(async (data) => {
          const { userId } = data;
          const { fullName, email } = data.employee;
          const accessBody = {
            refId: userId,
            fullname: fullName,
            email,
          };
          const { data: user } = await ApiService.get(
            `/findUserByRefId/refId-${userId}`
          );

          if (!user.data) {
            this.userInfo = accessBody;
            this.$bvModal.show("select-department-modal");
            this.$store.dispatch(LOADING_RESET);
          } else {
            this.accessByDCore(accessBody);
          }
          // this.accessByDCore(accessBody);
        })
        .catch(() => {
          this.$store.dispatch(LOADING_RESET);
          errorNotification(this.errors);
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    async accessByDCore(accessBody) {
      const { state, mutations } = AuthModule;
      await ApiService.post("/accessByDCore", accessBody)
        .then(({ data }) => {
          saveToken(data.data);
          mutations[SET_AUTH](state, data.data);
          successNotification("Đăng nhập thành công");
          // this.$router.push({ name: "home" });
        })
        .then(async () => {
          const { data } = await ApiService.get("/room/getList");
          const roomList = data.data.map((item) => {
            item.roomLink = removeAscent(item.roomName.replace(/ /g, ""));
            return item;
          });
          const firstRoom = roomList[0];
          this.resetForm();
          this.$router.push({ path: `/${firstRoom.id}/${firstRoom.roomLink}` });
          this.$store.dispatch(LOADING_RESET);
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["reloadCount"]),
  },
};
</script>

<style scoped>
.login-form-container {
  height: 500px;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.submit-button {
  color: white;
  background-color: #bd1e2d;
  cursor: pointer;
  transition: 0.3s;
}
.submit-button:hover {
  background-color: #de2437;
}
</style>
