<template>
  <b-modal
    id="select-department-modal"
    title="BootstrapVue"
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-form-group>
        <p class="ml-3" style="font-size: 20px">
          Vui lòng chọn phòng ban để tiếp tục đăng nhập.
        </p>
        <b-form-select
          id="inline-form-custom-select-pref"
          class="form-control form-control-solid h-auto"
          v-model="$v.form.departmentID.$model"
          :options="departmentListSelector"
          size="xl"
        ></b-form-select>

        <b-form-invalid-feedback>
          Vui lòng chọn phòng ban.
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="form-group pr-1">
        <b-button
          type="submit"
          class="w-100 font-weight-bold font-size-3"
          size="xl"
          variant="primary"
          ref="booking_meeting_room_submit"
        >
          Tiếp tục
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "select-department-modal",
  props: ["userInfo", "accessByDCore"],
  mixins: [validationMixin],

  data() {
    return {
      form: {
        departmentID: null,
      },
    };
  },
  validations: {
    form: {
      departmentID: {
        required,
      },
    },
  },

  methods: {
    resetForm() {
      this.form.meetingContent = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      //   const { state, mutations } = AuthModule;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const departmentId = this.$v.form.departmentID.$model;

      // set spinner to submit button
      const submitButton = this.$refs["booking_meeting_room_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const accessBody = {
        ...this.userInfo,
        departmentId,
      };
      this.accessByDCore(accessBody);

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
  },

  computed: {
    departmentListSelector() {
      return this.$store.getters.departmentList.map((item) => {
        return { text: item.departmentName, value: item.id };
      });
    },
  },
  watch: {
    departmentListSelector: {
      immediate: true,
      handler(departmentList) {
        if (!departmentList) return;
        this.form.departmentID = departmentList[0].value;
      },
    },
  },
};
</script>

<style scope>
.modal-header {
  padding: 1rem 2rem;
}
.modal-body {
  padding: 1rem;
}
.form-group {
  margin-bottom: 1rem;
}
.li-h-35 li {
  height: 35px !important;
  min-height: 0px;
}
</style>
